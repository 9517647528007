import { HeaderSegmentedControlConfigI, HeaderIconBtnsConfigI } from "../types/ui/header";
import { CirclePlus, FilePenLine, FolderPlus, PlayCircle, Plus, Share2, SlidersHorizontal, Zap, History, MessageSquare, LayoutList } from 'lucide-react';
import { ROUTE_ECHO_COLLECTIONS, ROUTE_ECHO_KNOWLEDGE, ROUTE_ECHO_METRICS, ROUTE_FETCH_KNOWLEDGE, ROUTE_FETCH_CHAT_ROOM, ROUTE_INDEX_CHAT_ROOM, ROUTE_ECHO_TAGS, ROUTE_FETCH_TAGS, ROUTE_FETCH_KNOWLEDGE_CONTENT, ROUTE_FETCH_COLLECTION_CONTENT, ROUTE_PROFILE } from '../constants/routes';
import { useAppDispatch } from "./reduxHooks";
import { setIsContentMenuOpen, setIsAddNewCategoryBtnClicked as setIsFetchAddNewCategoryBtnClicked, setIsUploadModalOpen } from "../state/features/fetchKnowledge/fetchKnowledgeSlice";
import { setIsContentMenuOpen as setIsProfileContentMenuOpen, setIsUploadModalOpen as setIsProfileUploadModalOpen } from "../state/features/profile/profileSlice";
import { useAppSelector } from "./reduxHooks";
import { IconButtonI } from "../types/ui/iconButton";
import { useMemo } from "react";
import { setIsFilterModalOpen, setIsSaveModalOpen } from "../state/features/agentEcho/agentEchoSlice";
import { InteractionCollectionI } from "../types/echo/collections";
import { AdminRolesEnum } from "../enums/userRoles";
import { setIsRunModalOpen, setIsShareModalOpen as setIsEchoCollectionsShareModalOpen } from "../state/features/echoCollections/echoCollectionsSlice";
import { setIsNewSession } from "../state/features/fetchChatRoom/fetchChatRoomSlice";
import { setIsSavedQueriesPanelOpen } from "../state/features/indexChatRoom/indexChatRoomSlice";
import { setIsAddNewCategoryBtnClicked as setIsEchoAddNewCategoryBtnClicked } from "../state/features/echoKnowledge/echoKnowledgeSlice";
import AdvancedMenu from "../components/Menus/AdvancedMenu";
import { ADD_CONTENT_MENU_ITEMS } from "../constants/fetch/common";
import { AdvancedMenuItemI } from "../types/ui/menus";
import { useNavigate } from "react-router-dom";

const useHeaderElements = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedEchoCollection: InteractionCollectionI | undefined = useAppSelector((state) => state.echoCollections.selectedCollection);
  const isFetchKnowledgeContentMenuOpen: boolean = useAppSelector((state) => state.fetchKnowledge.isContentMenuOpen);
  const isProfileContentMenuOpen: boolean = useAppSelector((state) => state.profile.isContentMenuOpen);
  const isInOrgMode: boolean | undefined = useAppSelector((state) => state.user.isInOrgMode);
  const { currentUser, firstCollection } = useAppSelector((state) => state.user);
  const canCreateCollection = currentUser?.ui_settings?.create_collection;

  const echoCollectionsIconButtons: IconButtonI[] = useMemo(() => {

    if (selectedEchoCollection) {

      const adminRolesArray: string[] = Object.values(AdminRolesEnum);
      const isAdminRole = adminRolesArray.includes(selectedEchoCollection.role);

      return isAdminRole ?
        [{ icon: PlayCircle, handleClick: () => { dispatch(setIsRunModalOpen(true)) } }, { icon: Share2, handleClick: () => { dispatch(setIsEchoCollectionsShareModalOpen(true)) } }] :
        [{ icon: PlayCircle, handleClick: () => { dispatch(setIsRunModalOpen(true)) } },]
    } else {
      return [];
    }
  }, [dispatch, selectedEchoCollection]);

  const getFetchKnowledgeContentMenuItems = () => {
    if (!canCreateCollection) {
      return [];
    }
    const menuItems: AdvancedMenuItemI[] = [
      {
        ...ADD_CONTENT_MENU_ITEMS[0],
        onClick: () => { dispatch(setIsUploadModalOpen(true)); dispatch(setIsContentMenuOpen(false)) }
      },
      {
        ...ADD_CONTENT_MENU_ITEMS[1],
        onClick: () => { navigate(ROUTE_FETCH_KNOWLEDGE_CONTENT); dispatch(setIsContentMenuOpen(false)) }
      },
    ]
    return menuItems;
  }

  const getProfileContentMenuItems = () => {
    if (!canCreateCollection) {
      return [];
    }
    const menuItems: AdvancedMenuItemI[] = [
      {
        ...ADD_CONTENT_MENU_ITEMS[0],
        onClick: () => { dispatch(setIsProfileUploadModalOpen(true)); dispatch(setIsProfileContentMenuOpen(false)) }
      },
      {
        ...ADD_CONTENT_MENU_ITEMS[1],
        onClick: () => { navigate(ROUTE_FETCH_KNOWLEDGE_CONTENT); dispatch(setIsProfileContentMenuOpen(false)) }
      },
    ]
    return menuItems;
  }

  const getProfilePageIconButtons = () => {
    if (isInOrgMode && canCreateCollection) {
      return [
        {
          icon: CirclePlus,
          handleClick: () => { dispatch(setIsProfileContentMenuOpen(true)) },
          popoverConfig: {
            isOpen: isProfileContentMenuOpen,
            content: <AdvancedMenu menuItems={getProfileContentMenuItems()} />,
            handleClickOutside: () => { dispatch(setIsProfileContentMenuOpen(false)) }
          }
        },
      ]
    } else {
      return [];
    }
  }

  const iconBtnsConfigurations: HeaderIconBtnsConfigI[] = [
    {
      route: ROUTE_FETCH_KNOWLEDGE,
      iconButtons: canCreateCollection
      ? [
        {
          icon: CirclePlus,
          handleClick: () => { dispatch(setIsContentMenuOpen(true)) },
          popoverConfig: {
            isOpen: isFetchKnowledgeContentMenuOpen,
            content: <AdvancedMenu menuItems={getFetchKnowledgeContentMenuItems()} />,
            handleClickOutside: () => { dispatch(setIsContentMenuOpen(false)) }
          }
        },
      ] : [],
    },
    {
      route: ROUTE_FETCH_TAGS,
      iconButtons: [
        { icon: Plus, handleClick: () => { dispatch(setIsFetchAddNewCategoryBtnClicked(true)) } },
      ]
    },
    {
      route: ROUTE_ECHO_METRICS,
      iconButtons: [
        { icon: FolderPlus, handleClick: () => { dispatch(setIsSaveModalOpen(true)) } },
        { icon: SlidersHorizontal, handleClick: () => { dispatch(setIsFilterModalOpen(true)) } },
      ]
    },
    {
      route: ROUTE_ECHO_KNOWLEDGE,
      iconButtons: [
        { icon: FolderPlus, handleClick: () => { dispatch(setIsSaveModalOpen(true)) } },
        { icon: SlidersHorizontal, handleClick: () => { dispatch(setIsFilterModalOpen(true)) } },
      ]
    },
    {
      route: ROUTE_ECHO_TAGS,
      iconButtons: [
        { icon: Plus, handleClick: () => { dispatch(setIsEchoAddNewCategoryBtnClicked(true)) } },
      ]
    },
    {
      route: `${ROUTE_ECHO_COLLECTIONS}/${selectedEchoCollection?.filter_id}`,
      iconButtons: echoCollectionsIconButtons
    },
    {
      route: ROUTE_FETCH_CHAT_ROOM,
      iconButtons: [{ icon: FilePenLine, handleClick: () => { dispatch(setIsNewSession(true)) } }]
    },
    {
      route: ROUTE_INDEX_CHAT_ROOM,
      iconButtons: []/*[{ icon: History, handleClick: () => { dispatch(setIsSavedQueriesPanelOpen(true)) } }]*/
    },
    {
      route: ROUTE_PROFILE,
      iconButtons: getProfilePageIconButtons()
    },
  ];

  const segmentedControlConfigurations: HeaderSegmentedControlConfigI[] = [
    // {
    //   route: ROUTE_FETCH_CHAT_ROOM,
    //   segmentedControlProps: {
    //     segments: [{
    //       id: 0,
    //       icon: MessageSquare,
    //       label: 'One chat',
    //       ref: useRef(null),
    //     }, {
    //       id: 1,
    //       icon: LayoutList,
    //       label: 'Feed',
    //       ref: useRef(null),
    //     }],
    //     defaultActiveIndex: 0,
    //     controlRef: useRef(null),
    //     handleChange: (selectedSegmentId: number) => {
    //       if (selectedSegmentId === 1) {
    //         navigate(ROUTE_FETCH_ORGANIZATION_FEED)
    //       }
    //     },
    //   }
    // },
    // {
    //   route: ROUTE_FETCH_ORGANIZATION_FEED,
    //   segmentedControlProps: {
    //     segments: [{
    //       id: 0,
    //       icon: MessageSquare,
    //       label: 'One chat',
    //       ref: useRef(null),
    //     }, {
    //       id: 1,
    //       icon: LayoutList,
    //       label: 'Feed',
    //       ref: useRef(null),
    //     }],
    //     defaultActiveIndex: 1,
    //     controlRef: useRef(null),
    //     handleChange: (selectedSegmentId: number) => {
    //       if (selectedSegmentId === 0) {
    //         navigate(ROUTE_FETCH_CHAT_ROOM)
    //       }
    //     },
    //   }
    // }
  ];


  return { iconBtnsConfigurations, segmentedControlConfigurations };
};

export default useHeaderElements;