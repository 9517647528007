export enum MsgTypeEnum {
    QUERY = 'query',
    TEXT = 'text',
    CONTEXT = 'context',
    DATA = 'data',
    ERROR = 'error',
    STATUS = 'status',
    CANCEL = 'cancel',
    TAG = 'tag',
    FEEDBACK = 'feedback',
    DOCUMENT = 'document',
    KEYWORD = 'keyword'
};

export enum DataMsgSubTypeEnum {
    TAGS = 'tags',
    FUQS = 'fuqs',
    SOURCES = 'sources',
    TOP_QUESTIONS = 'top_questions',
    CANCEL = 'cancel',
    FEEDBACK = 'feedback',
    TITLE = 'title',
};

export enum ResponseStatusEnum {
    QUESTION_SUBMITTED = 'Question Submitted...',
    RESPONSE_COMPLETE = 'Response Complete'
};

export enum CommandLineOptionsEnum {
    DOCUMENT = 'document',
    KEYWORD = 'keyword'
};

