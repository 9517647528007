import { Files, Tag } from "lucide-react";
import { CommandLineOptionI } from "../../types/fetch/chatRoom";
import { CommandLineOptionsEnum } from "../../enums/fetch/chatRoom";

export const COMMAND_LINE_OPTIONS: CommandLineOptionI[] = [
    {
        id: CommandLineOptionsEnum.DOCUMENT,
        title: '/Document',
        description: 'Look for data, content within a document',
        icon: Files,
        textColorClass: 'text-[#062821]',
        bgColorClass: 'bg-[#D5F6E6]',
        borderColorClass: 'border-[#39B88D]',
        removeIconBgColorClass: '!bg-[#9ADDC1]'
    },
    {
        id: CommandLineOptionsEnum.KEYWORD,
        title: '/Keyword',
        description: 'search for Keyword, dataset within a document',
        icon: Tag,
        textColorClass: 'text-amber-900',
        bgColorClass: 'bg-amber-50',
        borderColorClass: 'border-amber-500',
        removeIconBgColorClass: '!bg-[#E9E3C9]'
    }
];