import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useDebouncedCallback } from "use-debounce";

interface SearchBarI {
    debouncedDelay?: number,
    placeholderText?: string,
    isSearchResetTriggered?: boolean,
    setIsSearchResetTriggered?: (isTriggered: boolean) => void,
    handleSearch: (searchTerm: string) => void,
    handleClearSearch: () => void
}

const SearchBar = (props: SearchBarI) => {

    const { debouncedDelay = 500, placeholderText = 'Search', isSearchResetTriggered = false, setIsSearchResetTriggered, handleSearch, handleClearSearch } = props;

    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        if (isSearchResetTriggered && setIsSearchResetTriggered) {
            setSearchTerm('');
            setIsSearchResetTriggered(false);
        }
    }, [isSearchResetTriggered, setIsSearchResetTriggered]);

    const debouncedSearch = useDebouncedCallback((value: string) => {
        handleSearch(value);
    }, debouncedDelay);

    const onClearSearch = () => {
        setSearchTerm('');
        handleClearSearch();
    }

    return (
        <div className='w-full'>
            <div className="flex items-center border rounded-lg bg-white w-full">
                <div className='relative flex items-center w-full'>
                    <input
                        onKeyDown={(e) => e.stopPropagation()}
                        type="text"
                        placeholder={placeholderText}
                        value={searchTerm}
                        onChange={(e) => { setSearchTerm(e.target.value); debouncedSearch(e.target.value) }}
                        className="w-full p-2 mx-8 truncate text-sm placeholder-gray-300 bg-transparent outline-none border-0 focus:ring-0 focus:border-0"
                    />
                    <div className='absolute left-2'><MagnifyingGlassIcon className="w-5 h-5 text-gray-400" /></div>
                    {searchTerm && <div className='absolute right-2 flex justify-end'><XMarkIcon className="w-5 h-5 text-gray-400 hover:text-gray-500 cursor-pointer" onClick={onClearSearch} /></div>}
                </div>
            </div>
        </div>
    )
}

export default SearchBar;
