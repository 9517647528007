import { Fragment, useEffect, useState } from 'react'
import { Listbox as ReactListBox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utils/styleHelpers'
import Spinner from '../Loaders/Spinner'
import SearchBar from '../SearchBar/SearchBar'
import { MultiSelectListboxOptionI } from '../../types/ui/listBoxes'

interface MultiSelectListboxI {
    isLoading?: boolean,
    label: string,
    options: MultiSelectListboxOptionI[],
    listPosition?: 'top' | 'bottom',
    stylingClasses?: string,
    selectedOptions: MultiSelectListboxOptionI[],
    searchEnabled?: boolean,
    setSelectedOptions: (options: MultiSelectListboxOptionI[]) => void,
    handleSelectAll: () => void,
    handleDeselectAll: () => void
}

const MultiSelectListbox = (props: MultiSelectListboxI) => {

    const { isLoading = false, label, options, listPosition = 'bottom', stylingClasses = '', selectedOptions, searchEnabled = true, setSelectedOptions, handleSelectAll, handleDeselectAll } = props;

    const [filteredOptions, setFilteredOptions] = useState<MultiSelectListboxOptionI[]>([]);
    const [optionsListRef, setOptionsListRef] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        //Use to clear the search result and reset options list when closing the list
        if (!optionsListRef) {
            setFilteredOptions(options);
        }
    }, [options, optionsListRef]);

    const handleSearch = (searchTerm: string) => {
        const matchingOptions: MultiSelectListboxOptionI[] = options.filter((opt: MultiSelectListboxOptionI) => opt.label.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredOptions(matchingOptions);
    }

    const handleClearSearch = () => {
        setFilteredOptions(options);
    }

    return (
        <div>
            <ReactListBox value={selectedOptions} onChange={setSelectedOptions} multiple>
                <div className="relative mt-1">
                    <ReactListBox.Button className={classNames("relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-primary-color focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-color sm:text-sm", stylingClasses)}>
                        <div className='flex items-center'>
                            <span className="block truncate">{label}</span>
                            {selectedOptions.length > 0 && <div className='bg-primary-color text-white rounded-xl py-[2px] px-[10px] ml-2 text-xs'>{selectedOptions.length}</div>}
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </ReactListBox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ReactListBox.Options ref={setOptionsListRef} className={classNames("absolute px-3 z-[5] max-h-60 w-full rounded-md bg-white py-5 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm light-scroll-bar", listPosition === 'bottom' ? 'mt-1' : 'mb-1 bottom-full', !isLoading && 'overflow-auto')}>
                            {!isLoading ?
                                <>
                                    <div className='pb-2'>
                                        <div className='flex justify-between pb-2 text-sm font-medium flex-wrap'>
                                            <div className='text-slate-400'>Select collections</div>
                                            <div className='flex gap-5'>
                                                <div className={classNames(selectedOptions.length < options.length ? 'text-primary-color cursor-pointer' : 'text-slate-400')} onClick={() => handleSelectAll()}>Select all</div>
                                                <div className={classNames(selectedOptions.length > 0 ? 'text-primary-color cursor-pointer' : 'text-slate-400')} onClick={() => handleDeselectAll()}>Clear</div>
                                            </div>
                                        </div>
                                        {
                                            searchEnabled &&
                                            <SearchBar handleSearch={handleSearch} handleClearSearch={handleClearSearch} />
                                        }
                                    </div>
                                    {filteredOptions.length > 0 ?
                                        filteredOptions.map((opt, index) => (
                                            <ReactListBox.Option
                                                key={index}
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-slate-100 text-slate-600' : 'text-slate-400'}`}
                                                value={opt}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                            {opt.label}
                                                        </span>
                                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-color">
                                                            <input className='text-primary-color border-gray-200 !ring-transparent' type="checkbox" id={opt.value} checked={selected} />
                                                        </div>
                                                    </>
                                                )}
                                            </ReactListBox.Option>
                                        ))
                                        :
                                        <span className='py-2 pl-10 pr-4 text-rose-500 bg-red-50 flex'>Nothing found</span>}
                                </>
                                :
                                <div className='h-12 flex items-center justify-center'>
                                    <Spinner />
                                </div>
                            }
                        </ReactListBox.Options>
                    </Transition>
                </div>
            </ReactListBox>
        </div>
    )
}

export default MultiSelectListbox;
