import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { HIDE_HEADER_FROM_ROUTES, SHOW_GLOBAL_SEARCH_BAR_FROM_ROUTES } from "../constants/mainLayout";
import { useAppSelector } from "../hooks/reduxHooks";
import { ModuleEnum } from '../enums/app';

const useLayout = () => {

    const location = useLocation();
    const { currentModule } = useAppSelector((state) => state.app);

    const hideDefaultHeader: boolean = useMemo(() => {

        if (HIDE_HEADER_FROM_ROUTES.includes(location.pathname) || location.pathname.includes('/content/')
            || location.pathname.includes('/fetch/events/')) {
            return true;
        } else {
            return false
        }
    }, [location.pathname]);

    const showGlobalSearchBar: boolean = useMemo(() => {
        if (SHOW_GLOBAL_SEARCH_BAR_FROM_ROUTES.includes(location.pathname) && currentModule === ModuleEnum.FETCH) {
            return true;
        } else {
            return false;
        }
    }, [location.pathname])

    return { hideDefaultHeader, showGlobalSearchBar };
};

export default useLayout;
