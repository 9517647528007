import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FirstCollectionI, UserI } from '../../../types/user';

interface UserStateI {
  currentUser?: UserI;
  isInOrgMode?: boolean,
  firstCollection?: FirstCollectionI;
};

const initialState: UserStateI = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserI>) => {
      state.currentUser = action.payload;
      state.isInOrgMode = action.payload.ui_settings.is_org_mode;
    },
    setFirstCollection: (state, action: PayloadAction<{ first_collection: FirstCollectionI } | undefined>) => {
      state.firstCollection = action.payload?.first_collection;
    },
    clearUserState: (state) => {
      state.currentUser = undefined;
      state.firstCollection = undefined;
    }
  },
})

export const { setCurrentUser, setFirstCollection, clearUserState } = userSlice.actions;
export default userSlice.reducer;