import React, { useMemo, useState } from 'react';
import Header from "../Header/Header";
import SideBar from '../SideBar/SideBar';
import { Outlet, useLocation } from 'react-router-dom';
import MobileSideBar from '../MobileSideBar/MobileSideBar';
import { HIDE_SIDE_BAR_FROM_ROUTES } from '../../../constants/mainLayout';
import { classNames } from '../../../utils/styleHelpers';
import useLayout from '../../../hooks/useLayout';

const MainLayout = () => {

    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState<boolean>(false);

    const location = useLocation();
    const { hideDefaultHeader } = useLayout();

    const showSideBar: boolean = useMemo(() => {
        return !HIDE_SIDE_BAR_FROM_ROUTES.includes(location.pathname);
    }, [location]);

    return (
        <>
            <MobileSideBar isMobileSidebarOpen={isMobileSidebarOpen} setIsMobileSidebarOpen={setIsMobileSidebarOpen} />
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex">
                {showSideBar && <SideBar />}
            </div>
            <div className={classNames('h-screen flex flex-col', showSideBar && 'lg:pl-[70px] ')}>
                {!hideDefaultHeader && <Header setIsMobileSidebarOpen={setIsMobileSidebarOpen} />}
                <Outlet />
            </div>
        </>
    )
}

export default MainLayout;
