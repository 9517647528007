import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ConfirmationModalTypesEnum } from '../../enums/modals';
import { ConfirmationModalI, DeleteConfirmationModalI } from '../../types/ui/modals';
import { IconConfigBasicI } from '../../types/ui/common';
import { classNames } from '../../utils/styleHelpers';

const ConfirmationModal = (props: ConfirmationModalI | DeleteConfirmationModalI) => {

    const { text, textStylingClasses = '', titleDivStylingClasses = '', children, type = ConfirmationModalTypesEnum.DEFAULT, isModalOpen, setIsModalOpen } = props;

    const isDeleteConfirmationModal = (item: ConfirmationModalI | DeleteConfirmationModalI): item is DeleteConfirmationModalI => {
        return type === ConfirmationModalTypesEnum.DELETE;
    }

    const handleConfirmBtnClick = () => {
        props.confirmButtonConfig.onClick();
        if (isDeleteConfirmationModal(props) || !props.confirmButtonConfig.keepModalOpen) {
            setIsModalOpen(false);
        }
    }

    const handleCancelBtnClick = (cancelCallbackFunc?: () => void) => {
        cancelCallbackFunc?.();
        setIsModalOpen(false);
    }

    const renderIcon = (iconConfig: IconConfigBasicI) => {
        const { icon: Icon, stylingClasses } = iconConfig;
        return (<div className={`rounded-full p-2 mb-5 ${stylingClasses ? stylingClasses : 'bg-slate-600 text-white'}`}><Icon /></div>);
    }

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setIsModalOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className='sm:flex justify-center'>
                                    <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="w-full text-lg font-medium leading-6 text-slate-700 pb-6">
                                            <div className={classNames('flex items-center justify-center', titleDivStylingClasses)}>
                                                {isDeleteConfirmationModal(props) ?
                                                    <div>Are you sure you want to delete
                                                        <span className={classNames('font-bold', textStylingClasses)}>{` ${text}`}</span>{props.fromText && ` from ${props.fromText}`}?
                                                    </div>
                                                    : props.iconConfig ?
                                                        <div className='flex flex-col justify-center items-center'>
                                                            {renderIcon(props.iconConfig)}
                                                            <div className={textStylingClasses}>{text}</div>
                                                        </div> :
                                                        <div className={textStylingClasses}>{text}</div>
                                                }
                                            </div>
                                        </Dialog.Title>
                                    </div>
                                </div>
                                {children &&
                                    <div className='mb-6'>
                                        {children}
                                    </div>
                                }
                                <div className={classNames('sm:mt-3 sm:flex sm:flex-row gap-4 justify-center', !isDeleteConfirmationModal(props) && props.buttonSectionStylingClasses)}>
                                    {isDeleteConfirmationModal(props) ?
                                        <>
                                            <button className='bg-rose-500 text-white' onClick={() => handleConfirmBtnClick()}>Confirm</button>
                                            <button className='bg-white border border-gray-300 text-slate-400' onClick={() => setIsModalOpen(false)}>Cancel</button>
                                        </>
                                        :
                                        <>
                                            <button className={`${props.confirmButtonConfig?.stylingClasses || 'bg-rose-500 text-white'}`} onClick={() => handleConfirmBtnClick()}>{props.confirmButtonConfig.text || 'Confirm'}</button>
                                            <button className={` ${props.cancelButtonConfig?.stylingClasses || 'bg-white border border-gray-300 text-slate-400'}`} onClick={() => handleCancelBtnClick(props.cancelButtonConfig?.onClick)}>{props.cancelButtonConfig?.text || 'Cancel'}</button>
                                        </>
                                    }
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ConfirmationModal;