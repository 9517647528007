import { ROUTE_FETCH_KNOWLEDGE_CONTENT, ROUTE_PROFILE } from "./routes";

/**
Use 'HIDE_HEADER_FROM_ROUTES' and 'HIDE_SIDE_BAR_FROM_ROUTES' to define routes which do not have any url params. 
If you want to hide header/sidebar from a page which has url params in its url, 
then use useMainLayout hook file 
**/
export const HIDE_HEADER_FROM_ROUTES = [ROUTE_FETCH_KNOWLEDGE_CONTENT];
export const HIDE_SIDE_BAR_FROM_ROUTES = [ROUTE_FETCH_KNOWLEDGE_CONTENT];
export const SHOW_GLOBAL_SEARCH_BAR_FROM_ROUTES = [ROUTE_PROFILE]
