import React from 'react';
import { Transition } from '@headlessui/react';
import { CommandLineOptionI, CommandLineOptionIdType } from '../../types/fetch/chatRoom';
import { COMMAND_LINE_OPTIONS } from '../../constants/fetch/chatRoom';

interface CommandLinePopoverContentI {
    handleOptionSelect: (selectedOptionId: CommandLineOptionIdType) => void
};

const CommandLinePopoverContent = (props: CommandLinePopoverContentI) => {

    const { handleOptionSelect: handleSelectOption } = props;

    const renderOption = (option: CommandLineOptionI) => {
        const { icon: Icon } = option;

        return (
            <div key={option.id} className='flex flex-row items-center gap-4 px-4 py-4 hover:bg-gray-50' onClick={() => handleSelectOption(option.id)}>
                <div className={`w-10 h-10 ${option.bgColorClass} flex items-center justify-center rounded-lg border ${option.borderColorClass}`}>
                    <Icon size={16} className={option.textColorClass} />
                </div>
                <div>
                    <div className='text-sm font-medium text-gray-700'>{option.title}</div>
                    <div className='text-xs font-medium text-slate-400'>{option.description}</div>
                </div>
            </div>
        )
    };

    return (
        <Transition
            appear={true}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <div className="overflow-hidden rounded-lg bg-white shadow-lg mb-2">
                <div className='w-full text-xs font-medium text-gray-900 bg-gray-50 p-3'>Suggested commands</div>
                <div className="flex flex-col py-4">
                    {COMMAND_LINE_OPTIONS.map((option: CommandLineOptionI) =>
                        renderOption(option)
                    )}
                </div>
            </div>
        </Transition>
    )
}

export default CommandLinePopoverContent;
