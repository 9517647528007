import { UserRolesEnum } from "../enums/userRoles";

export const USER_ROLES_MAP: Record<number, `${UserRolesEnum}`> = {
  0: UserRolesEnum.ADMIN,
  1: UserRolesEnum.COLLABORATOR,
  2: UserRolesEnum.MEMBER
};

export const DEFAULT_ACCEPTED_FILE_TYPES = ['.pdf'];

export const DEFAULT_MAXIMUM_FILE_SIZE = 5; // in mb